'use strict'

const service = config.VUE_APP_service_licenze || ''

async function getElencoConfigurazioni(dbName, includeAllDocs) {
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}?keys=${includeAllDocs}`)
  const result = await response.json()
  return result.rows
}

async function modificaConfigurazione(dbName, configurazione) {
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(configurazione)
  })
  return await response.json()
}

async function modificaConfigurazioneSub(dbName, configurazioneId, subConf) {
  const data = {
    configurazioneId,
    subConf
  } 
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/sub/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function modificaConfigurazioneSubDettaglio(dbName, configurazioneId, subConf, payload) {
  const data = {
    configurazioneId,
    subConf,
    payload
  } 
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/sub/dettaglio/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function leggeConfigurazione(dbName, payload) {
  const confId = payload._id
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/${confId}`)
  return await response.json()
}

async function leggeConfigurazioneSub(dbName, confId, payload) {
  let subArr = ''
  if (typeof payload === 'string') {
    subArr = payload
  } else {
    subArr = Object.getOwnPropertyNames(payload).filter(x => !x.startsWith('__'))
  }
  const response = await fetch(`${service}/backoffice/configurazioni/${dbName}/${confId}?arr=${subArr}`)
  return await response.json()
}

async function getDatiGenerici(dbName, query, fields, limit) {
  var data = {
    query,
    fields,
    limit
  }
  const response = await fetch(`${service}/backoffice/generico/${dbName}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}

async function modificaItemGenerico(dbName, item) {
  const response = await fetch(`${service}/backoffice/generico/item/${dbName}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(item)
  })
  return await response.json()
}

async function getItemGenerico(dbName, payload) {
  try {
    const itemId = payload._id
    const response = await fetch(`${service}/backoffice/generico/item/${dbName}/${itemId}`)
    return await response.json()
  } catch {
    return null
  }
}

export default {
  getElencoConfigurazioni,
  modificaConfigurazione,
  modificaConfigurazioneSub,
  modificaConfigurazioneSubDettaglio,
  leggeConfigurazione,
  leggeConfigurazioneSub,
  getDatiGenerici,
  modificaItemGenerico,
  getItemGenerico
}
